import { Box, Dialog, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import dayjs from "dayjs";
import { TFunction } from "next-i18next";
import React, { useState } from "react";
import { Router } from "../../../i18n";
import useStyles from "../../../styles/components/DialogAuth";
import { firebase, loginWithGoogle } from "../../config/firebase";
import { ERROR_AUTH } from "../../libs/enum/auth";
import {
  checkAvailability,
  loginByVerifyOTP,
  loginWithFirebaseToken,
  registerByVerifyOTP,
  sendOTP,
} from "../../services/rooms";
import DialogLogin from "./DialogLogin";
import DialogOTP from "./DialogOTP";
import DialogRegister from "./DialogRegister";
import DialogResult from "./DialogResult";
import DialogVerifyMethod from "./DialogVerifyMethod";

type DialogAuthProps = {
  readonly t: TFunction;
  open: boolean;
  redirect?: string;
  onClose: () => void;
  handleSetLogin?: () => void;
};

const DialogAuth = (props: DialogAuthProps) => {
  const classes = useStyles();
  let timeout: NodeJS.Timeout;
  const { t, open, redirect, onClose, handleSetLogin } = props;
  const [authData, setAuthData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [stepDialog, setStepDialog] = useState({
    step: 1,
    auth: "login",
    verifyMethod: "",
  });
  const [error, setError] = useState("");

  const handleChangeAuthData = (value) => {
    if (value) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setAuthData({ ...value });
        localStorage.setItem("authData", JSON.stringify(value));
      }, 1000);
    }
  };

  const handleLoginGoogle = async () => {
    const tokenFirebase = await loginWithGoogle();
    if (tokenFirebase !== undefined) {
      const response = await loginWithFirebaseToken(tokenFirebase);
      localStorage.setItem("data_login", JSON.stringify(response.data));
      localStorage.setItem("token", response.data.token);
      if (response.meta.status_code === 200) {
        if (redirect) {
          Router.push(redirect);
        }
        localStorage.removeItem("authData");
        if (handleSetLogin) {
          handleSetLogin();
        }
        onClose();
        localStorage.setItem(
          "loginDate",
          dayjs(new Date()).format("YYYY-MM-DD")
        );
      }
    } else {
    }
  };

  const handleVerify = (auth: string) => {
    let data = {};
    if (auth === "login") {
      data = { phoneNumber: authData.phoneNumber };
    }
    if (auth === "register") {
      data = { email: authData.email, phoneNumber: authData.phoneNumber };
    }

    checkAvailability(data)
      .then((res) => {
        if (auth === "register") {
          setError("");
          localStorage.setItem("authData", JSON.stringify(authData));
          setStepDialog({
            auth: "register",
            verifyMethod: "phone",
            step: 2,
          });
        }
        if (auth === "login") {
          if (stepDialog.verifyMethod === "email") {
            setError(ERROR_AUTH.EMAIL_IS_NOT_REGISTERED);
            setStepDialog({
              auth: "login",
              verifyMethod: "email",
              step: 4,
            });
          } else {
            setError(ERROR_AUTH.PHONE_IS_NOT_REGISTERED);
            setStepDialog({
              auth: "login",
              verifyMethod: "phone",
              step: 4,
            });
          }
        }
      })
      .catch((error) => {
        if (auth === "login") {
          setError("");
          localStorage.setItem("authData", JSON.stringify(authData));
          setStepDialog({
            auth: "login",
            verifyMethod: "phone",
            step: 2,
          });
        }
        if (auth === "register") {
          setError(error.response.data.meta.message);
          setStepDialog({
            auth: "register",
            verifyMethod: "phone",
            step: 4,
          });
        }
      });
  };

  const handleSendOtpWA = () => {
    const data = {
      recipient: authData.phoneNumber,
      targetType: "PHONE",
    };
    sendOTP(data)
      .then((res) => {
        setStepDialog({
          ...stepDialog,
          verifyMethod: "phone-wa",
          step: 3,
        });
        setError("");
      })
      .catch((err) => {});
  };

  const resendOtpWa = () => {
    const data = {
      recipient: authData.phoneNumber,
      targetType: (stepDialog.verifyMethod as string).toUpperCase(),
    };
    sendOTP(data)
      .then((res) => {})
      .catch((error) => {});
  };

  const resendOtpFirebase = () => {
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(`+${authData.phoneNumber}`, appVerifier)
      .then((confirmationResult) => {
        localStorage.setItem(
          "confirmationResult",
          JSON.stringify(confirmationResult)
        );
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        window.recaptchaVerifier.render().then(function (widgetId) {
          window.grecaptcha.reset(widgetId);
        });
        // setIsError(false);
        // setClearCaptha(!clearCaptha);
        // setIsLoading(false);
      });
  };

  const handleResendOtp = () => {
    setError("");
    if (stepDialog.verifyMethod === "phone-sms") {
      resendOtpFirebase();
    } else {
      resendOtpWa();
    }
  };

  const handleRegister = (otp) => {
    const data = {
      name: authData.name,
      email: authData.email,
      phoneNumber: authData.phoneNumber,
      metadata: {
        isGuest: true,
      },
      targetType: (stepDialog.verifyMethod as string).toUpperCase(),
      otp: otp,
    };
    registerByVerifyOTP(data)
      .then((res) => {
        if (redirect) {
          Router.push(redirect);
        }
        localStorage.setItem("data_login", JSON.stringify(res.data));
        localStorage.setItem("token", res.data.token);
        localStorage.setItem(
          "loginDate",
          dayjs(new Date()).format("YYYY-MM-DD")
        );
        localStorage.removeItem("authData");
        if (handleSetLogin) {
          handleSetLogin();
        }
        onClose();
      })
      .catch((error) => {
        setError(error.response.data.meta.message);
      });
  };

  const handleLogin = (otp) => {
    const data = {
      recipient: authData.phoneNumber,
      targetType: (stepDialog.verifyMethod as string).toUpperCase(),
      otp: otp,
    };
    loginByVerifyOTP(data)
      .then((res) => {
        if (redirect) {
          Router.push(redirect);
        }
        localStorage.setItem("data_login", JSON.stringify(res.data));
        localStorage.setItem("token", res.data.token);
        localStorage.setItem(
          "loginDate",
          dayjs(new Date()).format("YYYY-MM-DD")
        );
        localStorage.removeItem("authData");
        if (handleSetLogin) {
          handleSetLogin();
        }
        onClose();
      })
      .catch((error) => {
        setError(error.response.data.meta.message);
      });
  };

  const handleAuth = (otp) => {
    if (stepDialog.auth === "login") {
      handleLogin(otp);
    }
    if (stepDialog.auth === "register") {
      handleRegister(otp);
    }
  };

  const renderTitleDialog = () => {
    if (stepDialog.step === 2) {
      return t("send-otp-method");
    }
    if (stepDialog.step === 3) {
      return t("input-otp-code");
    }
    if (stepDialog.auth === "register") {
      return t("sign-up");
    }
    if (stepDialog.step === 4 && error === ERROR_AUTH.EMAIL_IS_REGISTERED) {
      return t("email-registered");
    }
    if (stepDialog.step === 4 && error === ERROR_AUTH.PHONE_IS_REGISTERED) {
      return t("phone-registered");
    }
    if (stepDialog.step === 4 && error === ERROR_AUTH.EMAIL_IS_NOT_REGISTERED) {
      return t("email-is-not-registered");
    }
    if (stepDialog.step === 4 && error === ERROR_AUTH.PHONE_IS_NOT_REGISTERED) {
      return t("phone-is-not-registered");
    }
    if (stepDialog.step === 1 || stepDialog.auth === "login") {
      return t("login");
    }
  };

  return (
    <Dialog open={open} className={classes.dialogAuth}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{renderTitleDialog()}</Typography>
        <Close
          onClick={() => {
            onClose();
            setStepDialog({ auth: "login", step: 1, verifyMethod: "phone" });
          }}
          className={classes.closeIcon}
        />
      </Box>
      {stepDialog.auth === "login" && stepDialog.step === 1 && (
        <DialogLogin
          t={t}
          handleLoginGoogle={handleLoginGoogle}
          handleVerify={() => handleVerify("login")}
          handleChangeAuthData={handleChangeAuthData}
          handleSignUp={() => {
            setStepDialog({ auth: "register", step: 1, verifyMethod: "phone" });
          }}
        />
      )}
      {stepDialog.auth === "register" && stepDialog.step === 1 && (
        <DialogRegister
          t={t}
          handleLoginGoogle={handleLoginGoogle}
          handleChangeAuthData={handleChangeAuthData}
          handleVerify={() => handleVerify("register")}
          handleLogin={() => {
            setStepDialog({ auth: "login", step: 1, verifyMethod: "phone" });
          }}
        />
      )}
      {stepDialog.verifyMethod === "phone" && stepDialog.step === 2 && (
        <DialogVerifyMethod
          t={t}
          phoneNumber={authData.phoneNumber}
          handleSendOtpWa={handleSendOtpWA}
        />
      )}
      {stepDialog.verifyMethod === "phone-wa" && stepDialog.step === 3 && (
        <DialogOTP
          t={t}
          authData={authData}
          handleAuth={handleAuth}
          handleResendOtp={handleResendOtp}
          verifyMethod={stepDialog.verifyMethod as string}
          error={error}
        />
      )}
      {stepDialog.step === 4 && (
        <DialogResult
          t={t}
          error={error}
          authData={authData}
          auth={stepDialog.auth as string}
          handleChangeAuth={() =>
            setStepDialog({
              auth: stepDialog.auth === "login" ? "register" : "login",
              step: 1,
              verifyMethod: "",
            })
          }
          handleChangeData={() =>
            setStepDialog({
              ...stepDialog,
              step: 1,
              verifyMethod: "",
            })
          }
        />
      )}
    </Dialog>
  );
};

export default DialogAuth;
