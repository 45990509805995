const UsernamePrefixInput = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7274 20.447C19.2716 19.1712 18.2672 18.0439 16.8701 17.2398C15.4729 16.4358 13.7611 15.9999 12 15.9999C10.2389 15.9999 8.52706 16.4358 7.12991 17.2398C5.73276 18.0439 4.72839 19.1712 4.27259 20.447"
        stroke="#121212"
        strokeLinecap="round"
      />
      <circle
        cx="12"
        cy="7.99994"
        r="4"
        stroke="#121212"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default UsernamePrefixInput;
