const GoogleIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="17.1429"
        height="17.1429"
        transform="translate(0.428711 0.428711)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8857 9.18703C16.8857 8.60456 16.8334 8.0445 16.7364 7.50684H9V10.6843H13.4208C13.2304 11.7111 12.6516 12.581 11.7817 13.1635V15.2245H14.4364C15.9896 13.7945 16.8857 11.6887 16.8857 9.18703Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00025 17.2151C11.2181 17.2151 13.0775 16.4795 14.4366 15.225L11.7819 13.164C11.0464 13.6568 10.1054 13.948 9.00025 13.948C6.8608 13.948 5.04993 12.5031 4.40399 10.5615H1.65967V12.6898C3.01129 15.3743 5.78921 17.2151 9.00025 17.2151Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40366 10.5612C4.23938 10.0683 4.14603 9.54188 4.14603 9.00048C4.14603 8.45909 4.23938 7.93263 4.40366 7.43977V5.31152H1.65935C1.10301 6.42045 0.785645 7.675 0.785645 9.00048C0.785645 10.326 1.10301 11.5805 1.65935 12.6894L4.40366 10.5612Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00025 4.05318C10.2063 4.05318 11.2891 4.46763 12.1404 5.28159L14.4964 2.92558C13.0738 1.60009 11.2144 0.786133 9.00025 0.786133C5.78921 0.786133 3.01129 2.62688 1.65967 5.31146L4.40399 7.4397C5.04993 5.49815 6.8608 4.05318 9.00025 4.05318Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleIcon;
