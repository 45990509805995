import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      cursor: "pointer",
    },
    inputPhoneNumber: {
      "& .MuiOutlinedInput-adornedStart": {
        padding: 16,
      },
      "& .MuiOutlinedInput-input": {
        padding: 0,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 16,
      },
    },
    phoneCode: {
      display: "flex",
      width: "fit-content",
      alignItems: "center",
      cursor: "pointer",
    },
    dialCode: {
      paddingLeft: 8,
    },
    dividerPhoneCode: {
      height: 24,
    },
    loginButton: {
      borderRadius: 100,
      padding: "16px 0px",
      fontWeight: 700,
      fontSize: 16,
      width: "100%",
      minWidth: "fit-content",
      height: 47,
    },
    changeNumberButton: {
      borderRadius: 100,
      padding: "16px 0px",
      fontWeight: 700,
      fontSize: 16,
      width: "100%",
      minWidth: "fit-content",
      height: 47,
    },
    dividerOtherLoginMethod: {
      width: "25%",
    },
    otherLoginMethod: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 16,
      marginTop: 16,
    },
    loginMethodWrapper: {
      borderRadius: 100,
      border: "1px solid #989898",
      padding: 12,
      cursor: "pointer",
      display: "grid",
      placeItems: "center",
    },
    loginMethodIcon: {
      color: "black",
      width: 17,
      height: 17,
    },
    underlineButton: {
      fontWeight: 700,
      color: theme.palette.primary.main,
      textDecoration: "underline",
      cursor: "pointer",
    },
    verifyMethodWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
    },
    verifyMethod: {
      border: "1px solid #929292",
      borderRadius: 16,
      padding: "15px 16px",
      "& .MuiButton-label": {
        gap: 8,
      },
    },
    dialogSubtitle: {
      margin: "24px 0px",
    },
    phoneNumber: {
      fontWeight: 700,
    },
    buttonWrapper: {
      display: "flex",
      gap: 16,
    },
    registerFormWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      margin: "24px 0px",
    },
    errorMessageOtp: {
      color: "#EF1B43",
      marginTop: 24,
    },
    checkboxWrapper: {
      display: "flex",
      textAlign: "left",
      alignItems: "flex-start",
      gap: 8,
      marginBottom: 24,
    },
    checkbox: {
      padding: 0,
    },
    privacyPolicyButton: {
      textTransform: "lowercase",
    },
    dialogAuth: {
      "& .MuiDialog-paperWidthSm": {
        width: 420,
        padding: 24,
        borderRadius: 16,
        "@media (max-width:425px)": {
          width: "90vw",
        },
      },
    },
  })
);

export default useStyles;
