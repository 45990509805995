import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey:
    process.env.FIREBASE_API_KEY || "AIzaSyDcznDC85Byih_ShSX8StT9O0rAVhnOewA",
  authDomain: process.env.FIREBASE_AUTH_DOMAIN || "pesan-io.firebaseapp.com",
  projectId: process.env.FIREBASE_PROJECT_ID || "pesan-io",
  //   databaseURL: "https://great-hotel.firebaseio.com",
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET || "pesan-io.appspot.com",
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || "371872067332",
  appId:
    process.env.FIREBASE_APP_ID || "1:371872067332:web:71af72488fc746a5a9aa51",
  measurementId: process.env.FIREBASE_MEASUREMENT_ID || "G-QQN6G36NT3",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const provider = new firebase.auth.GoogleAuthProvider();

export const loginWithGoogle = () =>
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async function (result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = await result.user.getIdToken();
      // The signed-in user info.
      return token;
    })
    .catch(function (error) {});

export const login = (email: string, password: string) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const register = (email: string, password: string) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

var auth = firebase.auth();
export { auth, firebase };
