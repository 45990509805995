import { Box, Button, Typography } from "@material-ui/core";
import { WhatsApp } from "@material-ui/icons";
import { TFunction } from "next-i18next";
import React from "react";
import useStyles from "../../../styles/components/DialogAuth";

type ModalVerifyMethodProps = {
  readonly t: TFunction;
  phoneNumber: string;
  handleSendOtpWa: () => void;
};

const ModalVerifyMethod = (props: ModalVerifyMethodProps) => {
  const { t, phoneNumber, handleSendOtpWa } = props;
  const classes = useStyles();

  return (
    <Box textAlign="left">
      <Typography className={classes.dialogSubtitle}>
        {t("otp-will-sent-to")}{" "}
        <span className={classes.phoneNumber}>+{phoneNumber}</span>.{" "}
        {t("do-not-tell-anyone-else")}
      </Typography>
      <Box className={classes.verifyMethodWrapper}>
        <Button
          variant="outlined"
          className={classes.verifyMethod}
          onClick={handleSendOtpWa}
        >
          <WhatsApp />
          <Typography>{t("send-otp-via-wa")}</Typography>
        </Button>
        {/* <Button variant="outlined" className={classes.verifyMethod}>
          <ChatIcon />
          <Typography>{t("send-otp-via-sms")}</Typography>
        </Button> */}
      </Box>
    </Box>
  );
};

export default ModalVerifyMethod;
