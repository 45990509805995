import { Box, Button, Typography } from "@material-ui/core";
import { TFunction } from "next-i18next";
import React from "react";
import useStyles from "../../../styles/components/DialogAuth";
import { ERROR_AUTH } from "../../libs/enum/auth";

type ModalResultProps = {
  readonly t: TFunction;
  error: string;
  auth: string;
  authData: {
    name: string;
    email: string;
    phoneNumber: string;
  };
  handleChangeAuth: () => void;
  handleChangeData: () => void;
};

const ModalResult = (props: ModalResultProps) => {
  const { t, handleChangeAuth, handleChangeData, error, authData, auth } =
    props;
  const classes = useStyles();

  const renderData = () => {
    if (error.toLowerCase().includes("email")) {
      return authData.email;
    }
    if (error.toLowerCase().includes("phone")) {
      return `+${authData.phoneNumber}`;
    }
  };

  const renderButton = () => {
    if (error.toLowerCase().includes("email")) {
      return t("change-email");
    }
    if (error.toLowerCase().includes("phone")) {
      return t("change-number");
    }
  };

  const renderErrorMessage = () => {
    if (error === ERROR_AUTH.EMAIL_IS_REGISTERED) {
      return t("email-registered-message");
    }
    if (error === ERROR_AUTH.PHONE_IS_REGISTERED) {
      return t("phone-registered-message");
    }
    if (error === ERROR_AUTH.EMAIL_IS_NOT_REGISTERED) {
      return t("email-is-not-registered-message");
    }
    if (error === ERROR_AUTH.PHONE_IS_NOT_REGISTERED) {
      return t("phone-is-not-registered-message");
    }
  };

  return (
    <Box>
      <Typography className={classes.dialogSubtitle}>
        <span className={classes.phoneNumber}>{renderData()}</span>{" "}
        {renderErrorMessage()}
      </Typography>
      <Box className={classes.buttonWrapper}>
        <Button
          variant="outlined"
          disableElevation
          className={classes.changeNumberButton}
          onClick={handleChangeData}
        >
          {renderButton()}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.loginButton}
          onClick={handleChangeAuth}
        >
          {auth === "login" ? t("sign-up-account") : t("login")}
        </Button>
      </Box>
    </Box>
  );
};

export default ModalResult;
