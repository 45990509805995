import {
  Box,
  Button,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import { TFunction } from "next-i18next";
import React, { useEffect, useState } from "react";
import data_country from "../../../public/data_country";
import GoogleIcon from "../../../public/img/GoogleIcon";
import useStyles from "../../../styles/components/DialogAuth";
import PhoneCodePicker from "../NewPhoneCodePicker";

type ModalLoginProps = {
  readonly t: TFunction;
  handleLoginGoogle: () => void;
  handleVerify: () => void;
  handleChangeAuthData: (data: any) => void;
  handleSignUp: () => void;
};

const ModalLogin = (props: ModalLoginProps) => {
  const {
    t,
    handleLoginGoogle,
    handleVerify,
    handleChangeAuthData,
    handleSignUp,
  } = props;
  const classes = useStyles();
  const [openPhoneCodePicker, setOpenPhoneCodePicker] = useState(false);
  const [phoneCode, setPhoneCode] = useState({
    country_code: "ID",
    name: "Indonesia",
    dial_code: "+62",
  });
  const [inputPhoneNumber, setInputPhoneNumber] = useState("");

  const handleSelectPhoneCode = (data) => {
    setPhoneCode(data);
  };

  const removePhoneCode = (mobileNumber) => {
    const dialCode = phoneCode.dial_code;
    return mobileNumber?.slice(dialCode.length - 1);
  };

  const getCurrentDataCountry = (phoneNumber) => {
    const country = data_country.find((item) =>
      `+${phoneNumber}`.startsWith(item.dial_code)
    );
    if (country) {
      setPhoneCode(country);
    }
  };

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("authData") || "{}");
    if (authData.phoneNumber) {
      getCurrentDataCountry(authData.phoneNumber);
      setInputPhoneNumber(removePhoneCode(authData.phoneNumber));
    }
  }, []);

  const handleChangePhoneNumber = (value) => {
    let pattern = /^$|^[0-9]+$/;
    if (pattern.test(value)) {
      setInputPhoneNumber(value);
    } else {
      setInputPhoneNumber(value.replace(/[^0-9]/g, ""));
    }
  };

  useEffect(() => {
    const phoneNumber = phoneCode.dial_code.slice(1) + inputPhoneNumber;
    handleChangeAuthData({
      name: "",
      email: "",
      phoneNumber: phoneNumber,
    });
  }, [inputPhoneNumber, phoneCode]);

  return (
    <Box textAlign="center">
      <Box mt="24px" mb="16px">
        <TextField
          value={inputPhoneNumber}
          onChange={(event) => handleChangePhoneNumber(event.target.value)}
          placeholder={t("example-phone-number")}
          fullWidth
          id="standard-error-helper-text-phone-number"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  className={classes.phoneCode}
                  onClick={() => setOpenPhoneCodePicker(true)}
                >
                  <img
                    src={`/img/flags/${phoneCode?.country_code.toLocaleLowerCase()}.png`}
                    height={24}
                    width={36}
                    alt="flag"
                  />
                  <Typography className={classes.dialCode}>
                    {phoneCode?.dial_code}
                  </Typography>
                  <KeyboardArrowDownOutlined />
                </Box>
                <Divider
                  orientation="vertical"
                  className={classes.dividerPhoneCode}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        className={classes.loginButton}
        disabled={inputPhoneNumber?.length < 6}
        onClick={handleVerify}
      >
        {t("login")}
      </Button>
      <Box my="32px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Divider className={classes.dividerOtherLoginMethod} />
          <Typography variant="body2">{t("or-continue-with")}</Typography>
          <Divider className={classes.dividerOtherLoginMethod} />
        </Box>
        <Box className={classes.otherLoginMethod}>
          <Box
            className={classes.loginMethodWrapper}
            onClick={handleLoginGoogle}
          >
            <GoogleIcon />
          </Box>
          {/* <Box className={classes.loginMethodWrapper}>
            <Apple className={classes.loginMethodIcon} />
          </Box> */}
          {/* <Box className={classes.loginMethodWrapper}>
            <MailRounded className={classes.loginMethodIcon} />
          </Box> */}
        </Box>
      </Box>
      <Typography variant="body2">
        {t("did-not-have-account-yet?")}{" "}
        <span className={classes.underlineButton} onClick={handleSignUp}>
          {t("sign-up")}
        </span>
      </Typography>
      <PhoneCodePicker
        t={t}
        handleSelectPhoneCode={handleSelectPhoneCode}
        onClose={() => setOpenPhoneCodePicker(false)}
        open={openPhoneCodePicker}
      />
    </Box>
  );
};

export default ModalLogin;
