import {
  Box,
  Button,
  Checkbox,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { TFunction } from "next-i18next";
import React, { useEffect, useState } from "react";
import { i18n, Router } from "../../../i18n";
import data_country from "../../../public/data_country";
import EmailPrefixInput from "../../../public/img/EmailPrefixInput";
import GoogleIcon from "../../../public/img/GoogleIcon";
import UsernamePrefixInput from "../../../public/img/UsernamePrefixInput";
import useStyles from "../../../styles/components/DialogAuth";
import PhoneCodePicker from "../NewPhoneCodePicker";

type ModalRegisterProps = {
  readonly t: TFunction;
  handleLoginGoogle: () => void;
  handleVerify: () => void;
  handleChangeAuthData: (data: any) => void;
  handleLogin: () => void;
};

const ModalRegister = (props: ModalRegisterProps) => {
  const language = i18n.language;
  const {
    t,
    handleChangeAuthData,
    handleLoginGoogle,
    handleVerify,
    handleLogin,
  } = props;
  const classes = useStyles();
  const [openPhoneCodePicker, setOpenPhoneCodePicker] = useState(false);
  const [phoneCode, setPhoneCode] = useState({
    country_code: "ID",
    name: "Indonesia",
    dial_code: "+62",
  });
  const [formRegister, setFormRegister] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    isAgree: false,
  });

  const removePhoneCode = (mobileNumber) => {
    const dialCode = phoneCode.dial_code;
    return mobileNumber?.slice(dialCode.length - 1);
  };

  const getCurrentDataCountry = (phoneNumber) => {
    const country = data_country.find((item) =>
      `+${phoneNumber}`.startsWith(item.dial_code)
    );
    if (country) {
      setPhoneCode(country);
    }
  };

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("authData") || "{}");
    if (authData.phoneNumber) {
      getCurrentDataCountry(authData.phoneNumber);
      setFormRegister({
        ...authData,
        phoneNumber: removePhoneCode(authData.phoneNumber),
      });
    }
  }, []);

  const handleSelectPhoneCode = (data) => {
    setPhoneCode(data);
  };

  const handleChangeFormRegister = (key: string, value: string) => {
    setFormRegister({
      ...formRegister,
      [key]: value,
    });
  };

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    const phoneNumber = phoneCode.dial_code.slice(1) + formRegister.phoneNumber;
    handleChangeAuthData({
      ...formRegister,
      phoneNumber: phoneNumber,
    });
  }, [formRegister, phoneCode]);

  const disableSignupButton = () => {
    if (
      !formRegister.name ||
      !validateEmail(formRegister.email) ||
      formRegister.phoneNumber.length < 5 ||
      !formRegister.isAgree
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box textAlign="center">
      <Box className={classes.registerFormWrapper}>
        <TextField
          value={formRegister.name}
          placeholder={t("example-full-name")}
          fullWidth
          id="standard-error-helper-text-full-name"
          variant="outlined"
          onChange={(event) =>
            handleChangeFormRegister("name", event.target.value)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <UsernamePrefixInput />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          value={formRegister.email}
          placeholder={t("example-email")}
          fullWidth
          id="standard-error-helper-text-email"
          variant="outlined"
          onChange={(event) =>
            handleChangeFormRegister("email", event.target.value)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailPrefixInput color="#222222" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          value={formRegister.phoneNumber}
          onChange={(event) => {
            let pattern = /^$|^[0-9]+$/;
            if (pattern.test(event.target.value)) {
              handleChangeFormRegister("phoneNumber", event.target.value);
            } else {
              handleChangeFormRegister(
                "phoneNumber",
                event.target.value.replace(/[^0-9]/g, "")
              );
            }
          }}
          placeholder={t("example-phone-number")}
          fullWidth
          id="standard-error-helper-text-phone-number"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  className={classes.phoneCode}
                  onClick={() => setOpenPhoneCodePicker(true)}
                >
                  <img
                    src={`/img/flags/${phoneCode?.country_code.toLocaleLowerCase()}.png`}
                    height={24}
                    width={36}
                    alt="flag"
                  />
                  <Typography className={classes.dialCode}>
                    {phoneCode?.dial_code}
                  </Typography>
                  <KeyboardArrowDownOutlined />
                </Box>
                <Divider
                  orientation="vertical"
                  className={classes.dividerPhoneCode}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box className={classes.checkboxWrapper}>
        <Checkbox
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          className={classes.checkbox}
          value={formRegister.isAgree}
          onChange={(event) =>
            setFormRegister({ ...formRegister, isAgree: event.target.checked })
          }
        />
        <Typography variant="body2">
          {t("by-signing-up-an-account")}{" "}
          <span
            className={clsx(
              classes.privacyPolicyButton,
              classes.underlineButton
            )}
            onClick={() => Router.push("/privacy-policy")}
          >
            {t("privacy-policy")}
          </span>{" "}
          {language === "id" && "kami"}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        className={classes.loginButton}
        disabled={disableSignupButton()}
        onClick={handleVerify}
      >
        {t("sign-up")}
      </Button>
      <Box my="32px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Divider className={classes.dividerOtherLoginMethod} />
          <Typography variant="body2">{t("or-continue-with")}</Typography>
          <Divider className={classes.dividerOtherLoginMethod} />
        </Box>
        <Box className={classes.otherLoginMethod}>
          <Box
            className={classes.loginMethodWrapper}
            onClick={handleLoginGoogle}
          >
            <GoogleIcon />
          </Box>
          {/* <Box className={classes.loginMethodWrapper}>
            <Apple className={classes.loginMethodIcon} />
          </Box> */}
        </Box>
      </Box>
      <Typography variant="body2">
        {t("already-have-account?")}{" "}
        <span className={classes.underlineButton} onClick={handleLogin}>
          {t("login")}
        </span>
      </Typography>
      <PhoneCodePicker
        t={t}
        handleSelectPhoneCode={handleSelectPhoneCode}
        onClose={() => setOpenPhoneCodePicker(false)}
        open={openPhoneCodePicker}
      />
    </Box>
  );
};

export default ModalRegister;
